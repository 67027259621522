import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() status = ''

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  handlePush(): void {
    this.router.navigate(['regulacao/detalhes-solicitacao/1'])
  }

}
