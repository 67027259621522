<div class="container_timeline">
  <p>HISTÓRICO DO PEDIDO</p>
  <div class="content_timeline">
    <h3>Resposta da solicitação de justificativa</h3>
    <p>Favor liberar, Tipo de histórico enviado pelo prestador. Anexo + Justificativa</p>
    <span>03/09/2020 às 13:47:09</span>
  </div>

  <div class="content_timeline">
    <h3>Solicitação de justificativa</h3>
    <p>Auditor Hugo Rafaelli solicitou justificativa: encaminhar justificativa e ou plano de tratamento</p>
    <span>03/09/2020 às 08:35:38</span>
  </div>
</div>
