import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-card',
  templateUrl: './big-card.component.html',
  styleUrls: ['./big-card.component.scss']
})
export class BigCardComponent implements OnInit {
  @Input() alert = false;

  formRequestSelected = '';

  constructor() { }

  ngOnInit(): void {
  }

  handeRequest(e: any): any{
    if(e.value === '1' && this.formRequestSelected !== 'pending') {
      this.formRequestSelected = 'pending'
    } else if(e.value === '2'  && this.formRequestSelected !== 'disapproved'){
      this.formRequestSelected = 'disapproved'
    } else if(e.value === '3' && this.formRequestSelected !== 'approved'){
      this.formRequestSelected = 'approved'
    } else {
      this.formRequestSelected = ''
    }
  }

}
