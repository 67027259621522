<div class="container_search_box">
  <div class="container_search_box__Left">
    <h2>Olá, Filipe Rosmann</h2>
    <p>Caso você precise acessar uma guia específica, por favor, utilize o campo de busca abaixo.</p>
    <div class="container_searchbar">
      <img src="../../../../assets/icons/search-white.svg" alt="" />
      <input type="text" placeholder="Digite sua busca aqui (guia, beneficiário, prestador, ...)" (focus)="focusInput()" (focusout)="notFocused()"/>
    </div>
    <div class="container_button_white">
      <button type="button" (click)='handleSearchData()'>
        ENTRAR NA FILA
      </button>
    </div>
    <mai-modal *ngIf='show' (clickWrapper)='show = !show;' label='' title=''>
     <div class="modalContent" *ngIf="searchData">
       <div class="container_lottie">
          <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
       </div>
       <div class="content_modal_rest">
         <h2 class="block">Procurando guia</h2>
         <p class="sizeSearch block">Estamos procurando uma guia para você regular.</p>
       </div>
     </div>
     <div class="buttons" *ngIf="searchData">
      <mai-btn label="cancelar" (clicked)='show = !show' theme="danger"></mai-btn>
     </div>

     <div class="results-not-found">
        <div class="modalContent" *ngIf="!searchData">
          <div class="container_illustration_not">
            <img src="../../../../assets/images/illustrations/results-not-found.svg" alt="">
          </div>
          <div class="content_modal_rest">
            <h2 class="block">Não encontramos nenhuma guia</h2>
            <p class="block">Não há nenhuma guia aguardando regulação nos contextos selecionados.</p>
          </div>
        </div>
        <div class="buttons" *ngIf="!searchData">
          <mai-btn label="fechar" (clicked)='show = !show'></mai-btn>
        </div>
     </div>

    </mai-modal>
  </div>
  <div class="container_search_box__right">
    <img src="../../../../assets/images/illustrations/homepage-search.svg" alt="">
  </div>
</div>
