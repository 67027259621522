import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-guia',
  templateUrl: './container-guia.component.html',
  styleUrls: ['./container-guia.component.scss']
})
export class ContainerGuiaComponent implements OnInit {
  @Input() typeResponse = ''
  @Input() alert = false

  constructor() { }

  ngOnInit(): void {
  }

}
