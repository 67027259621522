import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailsRequestComponent } from './modules/RegulatorDoctor/details-request/details-request.component';
import { HomepageComponent } from './modules/RegulatorDoctor/homepage/homepage.component';

import { SigninComponent } from './modules/RegulatorDoctor/signin/signin.component'

const routes: Routes = [
  { path: '', component: SigninComponent },
  { path: 'regulacao/detalhes-solicitacao/:id', component: DetailsRequestComponent },
  { path: 'homepage', component: HomepageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
