<div class="container_history">
  <h2>Histórico</h2>
  <div class="container_searchbar">
    <mai-searchbar placeholder="Procurar no histórico (guia, procedimento, código do procedimento, ...)"><input /></mai-searchbar>
  </div>
  <div class="container_history_type">
    <div class="title">
      <p></p>
    </div>
    <div class="container_guides">
      <p class="container_guides_title">1416146 • Fundação Copel / Oncologia</p>
      <div class="guides">
        <app-card status="approved"></app-card>
      </div>
      <div class="guides">
        <app-card status="pending"></app-card>
      </div>
    </div>
    <div class="container_guides">
      <p class="container_guides_title">1424055 • Fundação Copel / Terapias seriadas</p>
      <div class="guides">
        <app-card status="approved"></app-card>
      </div>
    </div>
  </div>
</div>
