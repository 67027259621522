import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  email: string = ''
  senha: string = ''
  disabled: boolean = true

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  handleSignin(): void {
    this.router.navigate(['homepage'])
  }

}
