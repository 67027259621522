<div class="container" >
  <header>
    <mai-avatar (click)='handleDropdown()'></mai-avatar>
    <mai-dropdown
      [data]="[{ label: 'Editar meus dados', icon: 'mai-ic-edit' }, { label: 'Sair', icon: 'mai-ic-close'}]"
      *ngIf='showDropdown'
      (clicked)="handleDrawer($event)"
    ></mai-dropdown>
  </header>

  <mai-drawer
     (clickWrapper)='handleDrawerUndefined()'
     *ngIf='show'
  >
    <div class="contentDrawer">
      <div class="contentDrawer__header" >
        <header>
          <button type="button" (click)="handleDrawer($event)">
            <mai-svg-icon  icon="mai-ic-close" size='2'></mai-svg-icon>
          </button>

          <h2>Editar meus dados</h2>
        </header>

        <div class="container-avatar">
          <div class="avatar-edit">
            <mai-avatar size='large'></mai-avatar>
            <div class="btn-alt-avatar">
              <img src="../../../../assets/icons/camera.svg" alt="">
            </div>
          </div>
        </div>

        <form>
          <div class="content-form">
            <h2>Dados pessoais</h2>
            <div class="container-input">
              <label>Nome completo</label>
              <mai-input
                [isDefault]="false"
                placeholder="Insira seu nome"
              ><input /></mai-input>
            </div>

            <div class="container-input">
              <label>Telefone</label>
              <mai-input
                [isDefault]="false"
                placeholder="Insira seu telefone"
              ><input /></mai-input>
            </div>
          </div>

          <div class="content-form">
            <h2>Acesso à conta</h2>
            <div class="container-input">
              <label>Nova senha</label>
              <mai-input
                [isDefault]="false"
                placeholder="Insira aqui a nova senha"
              ><input type="password"/></mai-input>
            </div>

            <div class="container-input">
              <label>Confirmar senha</label>
              <mai-input
                [isDefault]='false'
                placeholder="Insira aqui a nova senha"
              ><input type="password"/></mai-input>
            </div>
          </div>

          <div class="conteiner-button">
            <mai-btn label="SALVAR"></mai-btn>
          </div>
        </form>
      </div>
    </div>
  </mai-drawer>

  <div select=".content" class="content">
    <ng-content></ng-content>
  </div>
</div>
