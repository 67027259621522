<div class="container">
  <div class="container__logo">
    <img src="../../../../assets/logos/octopus-white-large.svg" alt="octopus"/>
  </div>

  <div class="container__form">
    <form>
      <h1>Bem-vindo ao Octopus</h1>
      <p>Preencha os campos abaixo para acessar o sistema.</p>

      <mai-input
        placeholder="e-mail"
        iconLeft="../../../../assets/icons/mail.svg"
      ><input #emailInput type="email" (keyup)="email = emailInput.value"/></mai-input>
      <mai-input
        placeholder="senha"
        iconLeft="../../../../assets/icons/password.svg"
      ><input type="password" #senhaInput (keyup)="senha = senhaInput.value"/></mai-input>
      <mai-btn label="entrar" [disabled]="!email || !senha" (click)="handleSignin()"></mai-btn>
    </form>

    <p>Octopus é um produto <a href="#">maida.health.</a></p>
  </div>
</div>
