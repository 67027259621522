import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ap-details-request',
  templateUrl: './details-request.component.html',
  styleUrls: ['./details-request.component.scss']
})
export class DetailsRequestComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params.id
  }

}
