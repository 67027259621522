<div class="container">
  <div class="content">
    <div class="area-one">
      <button tyoe="button" (click)="handleBackPage()">
        <img src="../../../../assets/icons/arrow-left.svg" alt=""/>
      </button>
      <div>
        <mai-header [label]="headerLeft"></mai-header>
        <ng-content select=".details"></ng-content>
      </div>
    </div>

    <div class="area-two">
      <div class="area-two__stick">
        <div class="container_overflow">
          <mai-overflow [data]="[{ label: 'Arquivar guia', icon: 'mai-ic-drilldown' }, { label: 'Ir para a próxima guia', icon: 'mai-ic-chevron-double-right' }]"></mai-overflow>
        </div>
        <div class="contaiern_content_area_two">
          <mai-header [label]="headerRight"></mai-header>
          <ng-content select=".painel-main"></ng-content>
        </div>
      </div>
    </div>

  </div>
</div>
