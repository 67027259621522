import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './modules/RegulatorDoctor/signin/signin.component';
import { ShareComponentModule } from './shared/dls/share-component.module';
import { DetailsRequestComponent } from './modules/RegulatorDoctor/details-request/details-request.component';
import { TemplateTwoColumnsComponent } from './core/layouts/template-two-columns/template-two-columns.component';
import { BigCardComponent } from './shared/components/big-card/big-card.component';
import { SmallCardComponent } from './shared/components/small-card/small-card.component';
import { HomepageComponent } from './modules/RegulatorDoctor/homepage/homepage.component';
import { TemplateOneColumnComponent } from './core/layouts/template-one-column/template-one-column.component';
import { ContainerGuiaComponent } from './shared/components/container-guia/container-guia.component';
import { ArchivedGuidesComponent } from './shared/components/archived-guides/archived-guides.component';
import { SearchBoxComponent } from './shared/components/search-box/search-box.component';
import { HistoryBoxComponent } from './shared/components/history-box/history-box.component';
import { CardComponent } from './shared/components/history-box/card/card.component';
import { GuideComponent } from './shared/components/archived-guides/guide/guide.component';
import { AlertFixedComponent } from './shared/components/alert-fixed/alert-fixed.component';
import { TimelineOrderComponent } from './shared/components/timeline-order/timeline-order.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    DetailsRequestComponent,
    TemplateTwoColumnsComponent,
    BigCardComponent,
    SmallCardComponent,
    HomepageComponent,
    TemplateOneColumnComponent,
    ContainerGuiaComponent,
    ArchivedGuidesComponent,
    SearchBoxComponent,
    HistoryBoxComponent,
    CardComponent,
    GuideComponent,
    AlertFixedComponent,
    TimelineOrderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareComponentModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
