import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  handlePush(): void {
    this.router.navigate(['regulacao/detalhes-solicitacao/1'])
  }

}
