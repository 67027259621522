<div class="container-card">
  <h3>Radioterapia Conformada Tridimensional (RCT-3D) com Acelerador Linear - por tratamento</h3>

  <div class="container-pdfs">
    <mai-btn iconLeft='../../../../assets/icons/pdf.svg' type="text" theme='info' label="VISUALIZAR DIRETRIZ"></mai-btn>
  </div>

  <app-alert-fixed *ngIf="alert"></app-alert-fixed>

  <div class="container-infos">
    <div>
      <p>Codigo do procedimento: <strong>41203062</strong></p>
      <p>Quantidade: <strong>1</strong></p>
    </div>

    <div>
      <p>Status no cliente: <strong>Em análise</strong></p>
      <p>Número de campos: <strong>210</strong></p>
    </div>

    <div>
      <p>Data prevista: <strong>01/12/2021 00:00:00 -03:00</strong></p>
    </div>

    <div>
      <p>Dose dia: <strong>2</strong></p>
      <p>Dose total: <strong>70</strong></p>
      <p>Número de dias: <strong>35</strong></p>
    </div>

    <div>
      <p>CRM do médico solicitante: <strong>CRM/PR - 27193</strong></p>
    </div>

    <div>
      <p>Quat. solicitada no ano: <strong>0</strong></p>
      <p>Quant. autorizda no ano: <strong>0</strong></p>
    </div>

    <div>
      <p>Quat. executada no ano: <strong>0</strong></p>
    </div>
  </div>

  <div class="container-btns">
    <mai-replybtn
      [data]="[{ type: 'warning', label: 'INFORMAR PENDÊNCIA', value: '1' }, { type: 'danger', label: '', value: '2' }, { type: 'success', label: '', value: '3' }]"
      (clicked)="handeRequest($event)"
    >
    </mai-replybtn>
  </div>

  <div class="request_order">

    <div class="container_autorization_qtd" *ngIf="formRequestSelected === 'approved'">
      <h3>Quantidade autorizada</h3>
      <div class="container_select_qtd">
        <mai-select [isDefault]="false" [data]="[{ label: '1', value: '1' }, { label: '2', value: '2' }, { label: '3', value: '3' }]" placeholder='Selecione a quantidade...' ></mai-select>
      </div>
    </div>

    <div class="container_reason" *ngIf="formRequestSelected.length > 0">
      <h3>Qual o motivo dessa decisão?</h3>
      <div class="container_input_reason">
        <mai-input
          iconRight='mai-ic-formpack'
          [isDefault]='false'
          placeholder="Insira o motivo aqui..."
        ><input/></mai-input>
      </div>
    </div>
  </div>

</div>
