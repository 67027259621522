<ap-template-two-columns
  headerLeft="114723550 • Fundação Copel"
  headerRight="Solicitações"
>
    <div class="details">
      <div class="order-infos">
        <p class="title">INFORMAÇÕES DO PEDIDO</p>

        <div class="order-infos__inf">
          <div>
            <p>Nome do Beneficiário</p>
            <strong>Maria de Lourdes Santos de Menezes</strong>
          </div>

          <div>
            <p>Código do Beneficiário</p>
            <strong>217951</strong>
          </div>
        </div>

        <div class="order-infos__inf">
          <div>
            <p>Sexo</p>
            <strong>Feminino</strong>
          </div>

          <div>
            <p>Idade</p>
            <strong>60</strong>
          </div>

          <div>
            <p>CID principal</p>
            <strong>D45 - Policitemia vera</strong>
          </div>
        </div>

        <div class="order-infos__inf">
          <div>
            <p>Pretador</p>
            <strong>NOS - Nucleo de oncologia de Sergipe</strong>
          </div>
        </div>

        <div class="order-infos__inf">
          <div>
            <p>Data da solicitação</p>
            <strong>09/01/2021</strong>
          </div>
        </div>

      </div>

      <div class="clinical-condition">
        <p class="title">QUADRO CLINICO</p>
        <p>Carcinoma invasivo de padrão lobular</p>
      </div>

      <div class="container-attchments">
        <p class="title">ANEXOS</p>
        <div class="attchments">
          <app-small-card></app-small-card>
          <app-small-card></app-small-card>
          <app-small-card></app-small-card>
          <app-small-card></app-small-card>
          <app-small-card></app-small-card>
        </div>
      </div>

      <app-timeline-order></app-timeline-order>

      <div class="history">
        <p class="title">INFORMAÇÕES DO PEDIDO</p>
        <mai-searchbar><input /></mai-searchbar>

        <div class="history_guia">
          <app-container-guia typeResponse="success"></app-container-guia>
          <app-container-guia typeResponse="danger" [alert]="true"></app-container-guia>
          <app-container-guia typeResponse="warning"></app-container-guia>
        </div>
      </div>

      <div class="loading__more">
        <mai-btn type='text' label="carregar mais itens"></mai-btn>
      </div>
    </div>

    <div class="painel-main">
       <div class="container-type-request">
          <p class="title">PROCEDIMENTOS / ONCOLOGIA</p>
          <app-big-card></app-big-card>
          <app-big-card [alert]="true"></app-big-card>
          <app-big-card></app-big-card>
       </div>
       <div class="painel-main__buttons">
         <mai-btn label="enviar respostas"></mai-btn>
       </div>
    </div>
</ap-template-two-columns>
