<div class="container_card" (click)="handlePush()">
  <div class="container_card_left">
    <h3>Radioterapia Conformada Tridimensional (RCT-3D) com acele...</h3>
    <div class="container_sub_label">
      <div class="sub_label">
        <strong>Código: </strong>
        <p>40301109</p>
      </div>
      <div class="sub_label">
        <strong>Quantidade autorizada: </strong>
        <p>1</p>
      </div>
      <div class="sub_label">
        <strong>Data da regulação: </strong>
        <p>04/01/2021</p>
      </div>
    </div>
  </div>
  <div class="container_card_right" *ngIf="status === 'approved'">
    <img src="../../../../../assets/icons/approved.svg" alt="">
    <p>Autorizado</p>
  </div>
  <div class="container_card_right" *ngIf="status === 'pending'">
    <img src="../../../../../assets/icons/warning.svg" alt="">
    <p>Pendente</p>
  </div>
</div>
