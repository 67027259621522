import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-archived-guides',
  templateUrl: './archived-guides.component.html',
  styleUrls: ['./archived-guides.component.scss']
})
export class ArchivedGuidesComponent implements OnInit {

  data = 'asdasd'

  constructor() { }

  ngOnInit(): void {
  }

}
