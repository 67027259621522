<div class="container-guia">
  <p class="title">GUIA 116264500 • ONCOLOGIA</p>

<div class="container-card">
  <h3>Radioterapia Conformada Tridimensional (RCT-3D) com Acelerador Linear - por tratamento</h3>

  <div class="container_alert" *ngIf="alert">
    <div class="conteiner_alert__icon">
      <img src="../../../../assets/icons/danger-white.svg" alt="">
    </div>
    <div class="conteiner_alert__content">
      <p>Procedimentos e solicitações de oncologia necessitam de perícia médica</p>
    </div>
  </div>


  <div class="response-guia">
    <div *ngIf="typeResponse === 'success'">
      <img src="../../../../assets/icons/approved.svg" alt="">
      <p>Autorizado por auditoria médica</p>
    </div>

    <div *ngIf="typeResponse === 'danger'">
      <img src="../../../../assets/icons/fail-red.svg" alt="">
      <p>Autorizado por auditoria médica</p>
    </div>

    <div *ngIf="typeResponse === 'warning'">
      <img src="../../../../assets/icons/warning.svg" alt="">
      <p>Autorizado por auditoria médica</p>
    </div>
  </div>

  <div class="container-infos">
    <div>
      <p>Codigo do procedimento: <strong>41203062</strong></p>
      <p>Quantidade: <strong>1</strong></p>
    </div>

    <div>
      <p>Status no cliente: <strong>Em análise</strong></p>
      <p>Número de campos: <strong>210</strong></p>
    </div>

    <div>
      <p>Data prevista: <strong>01/12/2021 00:00:00 -03:00</strong></p>
    </div>

    <div>
      <p>Dose dia: <strong>2</strong></p>
      <p>Dose total: <strong>70</strong></p>
      <p>Número de dias: <strong>35</strong></p>
    </div>

    <div>
      <p>CRM do médico solicitante: <strong>CRM/PR - 27193</strong></p>
    </div>

    <div>
      <p>Quat. solicitada no ano: <strong>0</strong></p>
      <p>Quant. autorizda no ano: <strong>0</strong></p>
    </div>

    <div>
      <p>Quat. executada no ano: <strong>0</strong></p>
    </div>
  </div>
</div>

</div>
