<app-template-one-column>
    <div class="content">
      <div class="content__left">
        <div class="container_search">
          <app-search-box></app-search-box>
        </div>
        <div class="container_history">
          <app-history-box></app-history-box>
        </div>
      </div>
      <div class="content__right">
        <app-archived-guides></app-archived-guides>
      </div>
    </div>
</app-template-one-column>

