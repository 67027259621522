<div class="archived">
  <div class="archived_title">
    <p>GUIAS ARQUIVADAS</p>
  </div>
  <div class="archived_content">
    <div class="archived_content_is_empty" *ngIf="!data">
      <img src="../../../../assets/images/illustrations/homepage-archived.svg" alt="">
      <p>Você não arquivou nenhuma guia ainda</p>
    </div>
    <div class="archived_content_not_empty">
      <div class="container_guide">
        <app-guide></app-guide>
      </div>
      <div class="container_guide">
        <app-guide></app-guide>
      </div>
    </div>
  </div>
</div>
