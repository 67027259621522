import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {
  show = false
  searchData = true

  options: AnimationOptions = {
    path: '../../../../assets/lottie/octopus-search.json',
  };

  constructor() { }

  ngOnInit(): void {
  }

  handleSearchData() {
    this.show = !this.show

    if(this.show) {
      setTimeout(() => {
        this.searchData = false
      }, 5000)
    }
  }

  animationCreated(animationItem: AnimationItem): void {
  }

  focusInput(): void {
    let container: any = document.querySelector('.container_searchbar')
    container.classList.add("isFocused");
  }

  notFocused(): void {
    let container: any = document.querySelector('.container_searchbar')
    container.classList.remove("isFocused");
  }
}
