import { Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-template-one-column',
  templateUrl: './template-one-column.component.html',
  styleUrls: ['./template-one-column.component.scss']
})
export class TemplateOneColumnComponent implements OnInit {
  @ViewChild('page', { static: true })

  show: boolean = false
  showDropdown: boolean = false
  applyHover: boolean = false
  subClick: any = []

  constructor() { }

  ngOnInit(): void {
  }

  handleDrawer(e: any) {


    if(e.label !== 'Sair'){
      this.show = !this.show;
    }
  }

  handleDrawerUndefined() {
    this.show = !this.show;
  }


  /* FUNCOES RELACIONADAS AO DROPDOWN*/

  handleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.observeClick();
  }

  observeClick() {
    const click = fromEvent(document, 'click');
    let considerClick = false;
    this.subClick.push(click.subscribe((e) => {
      if (considerClick) {
        this.showDropdown = false;
        this.unsubscribe();
        return;
      }
      considerClick = true;
    }));
  }

  unsubscribe() {
    this.subClick.forEach((sub: any) => {
      sub.unsubscribe();
    });
    this.subClick = [];
  }

  // selectOption(e: any) {
  //   this.clicked.emit(e);
  // }

}
